/*@font-face {*/
/*  font-family: "Ubuntu Condensed";*/
/*  !*src: url("https://canererdogan.tech/assets/fonts/UbuntuCondensed-Regular.ttf");*!*/
/*  !*src: url("/assets/fonts/UbuntuCondensed-Regular.ttf");*!*/
/*}*/
/*@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600&display=swap');

:root {
  --width: 100vw;
  --height: 100vh;
  --duration: 310ms;
  --color: #2d2d2d;
  --inactive-color: #414141;
  --active-color: #2352aa;
  --link-color: #ca6dff;
  --mail-tooltip: 'Click to copy';
  --dark-color: #31374c;
  --tv-color: #fab900;
  /*--background-color: white;*/
  --background-color: #e1e1e1;

  /*!** sunny side **!*/
  /*--blue-background: #C2E9F6;*/
  /*--blue-border: #72cce3;*/
  /*--blue-color: #96dcee;*/
  /*--yellow-background: #fffaa8;*/
  /*--yellow-border: #f5eb71;*/
  /*!** dark side **!*/
  /*--indigo-background: #808fc7;*/
  /*--indigo-border: #5d6baa;*/
  /*--indigo-color: #6b7abb;*/
  /*--gray-border: #e8e8ea;*/
  /*--gray-dots: #e8e8ea;*/
  /*!** general **!*/
  /*--white: #fff;*/
}

html, body, canvas {
  margin: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--color);
  font-family: "Raleway", sans-serif;
}

html {
  background-color: var(--background-color);
  /*background-image: radial-gradient(closest-side, var(--background-color) 61.8%, black 220%);*/
  /*background-image: radial-gradient(closest-side, rgb(35.2, 38.75, 79.82) 10%, black 220%);*/
  transition: background-color 250ms ease-in;
}

canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.Home {
  width: 100%;
  height: var(--height);
  display: grid;
  place-items: center;
  overflow: hidden;
  background-image: radial-gradient(closest-side, transparent 61.8%, black 220%);
  pointer-events: all;
}

#Home-wrapper {
  position: absolute;
  width: min(var(--width), 1920px);
  height: var(--height);
  overflow: hidden;
  display: grid;
  place-items: center;
}

#error {
  margin: 0;
}

#name {
  position: absolute;
  width: max-content;
  margin: 1.6rem;
  font-size: 2rem;
  font-weight: 600;
  top: 0;
  right: 0;
  /*z-index: -1;*/
  pointer-events: none;
  text-align: right;
  transition: color var(--duration);
  /*opacity: 0.25;*/
}

#name span {
  margin: 0;
  font-weight: 200;
  letter-spacing: -0.1rem;
}

.portrait #name { margin: 1rem; }

.portrait #name br {
  display: block;
  content: "";
  margin-top: -1.3rem;
}

/*.portrait #main-name {*/
/*  !*bottom: 0;*!*/
/*  !*left: 0;*!*/
/*  !*font-size: 10rem;*!*/
/*  !*transform: rotate(-90deg);*!*/
/*  !*transform-origin: right;*!*/
/*}*/

img { pointer-events: none; }
a:link { color: var(--link-color); }
a:visited { color: var(--link-color); }
.pointer { cursor: pointer; }

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: all 0.12s;
}

button {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.section {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 10%;
  left: 50%;
  width: 40rem;
  max-height: 50rem;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  /*box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.1);*/
  /*-moz-box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.1);*/
  /*-o-box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.1);*/
  /*-webkit-box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.1);*/
  /*border: 1px solid rgb(129, 153, 164);*/
  border-radius: 1rem;
  background-image: linear-gradient(
    170deg,
    transparent 50%,
    var(--dark-color) 200%
    /*#6461fd 200%*/
  );
  backdrop-filter: blur(1rem) saturate(100%);
  -webkit-backdrop-filter: blur(1rem) saturate(100%);
  z-index: 2;
  /*background-color: rgba(17, 25, 40, 0.81);*/
}

.portrait .section {
  background-image: linear-gradient(
      10deg,
      transparent 50%,
      var(--dark-color) 200%
      /*#6461fd 200%*/
  );
}

.section .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section .header button {
  margin-bottom: 1rem;
  font-weight: 600;
}

.section-content {
  overflow-y: auto;
  height: 100%;
  padding-right: 1rem;
  /*font-size: 1rem;*/
  /*line-height: 1rem;*/
}

.section-content::-webkit-scrollbar {
  width: 0.4rem;
  /*left: -1rem;*/
  /*padding: 0.25rem;*/
}

/* Track */
.section-content::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px grey;*/
  /*-webkit-border-radius: 10px;*/
  /*border-radius: 10px;*/
  background: transparent;
}

/* Handle */
.section-content::-webkit-scrollbar-thumb {
  /*border: 0.1rem solid transparent;*/
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  background: #a4a4a4;
  /*-webkit-box-shadow: inset 6px 6px 6px grey;*/
}
.section-content::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.32);
}

.section-content p,
.section-content a {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.portrait .section-content p,
.portrait .section-content a {
  font-size: 1rem;
  line-height: 1.4rem;
}

#timeline {
  align-content: center;
  align-items: center;
  counter-reset: stepCount;
  display: flex;
  margin-left: -0.5rem;
  justify-content: space-between;
}

#timeline .timestamp {
  background-image: linear-gradient(to right, var(--active-color), var(--active-color) 100%);
  color: var(--color);
  content: ' ';
  display: flex;
  flex-grow: 1;
  height: 0.3rem;
  line-height: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
  text-align: right;
  cursor: pointer;
  transition: all 0.6s;
  /*z-index: -1;*/
}

#timeline .timestamp::before {
  color: #040082;
  background-image: linear-gradient(to right, var(--active-color), var(--active-color) 100%);
  border-radius: 50%;
  counter-increment: stepCount;
  content: '';
  /*content: counter(stepCount);*/
  height: 2rem;
  left: -2rem;
  line-height: 2rem;
  position: absolute;
  text-align: center;
  top: -0.85rem;
  width: 2rem;
  transition: all 0.3s;
  font-size: 0.75rem;
}

#timeline .timestamp.active {
  transition: all 0.6s;
  background-image: linear-gradient(to right, var(--inactive-color), var(--inactive-color) 100%);
}

#timeline .timestamp.recent {
  transition: all 0.6s;
  background-image: linear-gradient(to right, var(--active-color), white 75%);
}

#timeline .timestamp.active ~ .timestamp {
  background-image: linear-gradient(to right, var(--inactive-color), var(--inactive-color) 100%);
}

#timeline .timestamp.active ~ .timestamp::before {
  color: var(--color);
  background-image: linear-gradient(to right, var(--inactive-color), var(--inactive-color) 100%);
}

#timeline .timestamp.active::before {
  background-image: linear-gradient(to right, white, white 100%);
  box-shadow: 0 0 1rem white;
  -moz-box-shadow:  0 0 1rem white;
  -o-box-shadow:  0 0 1rem white;
  -webkit-box-shadow:  0 0 1rem white;
  transform: scale(1.2);
}

#timeline .timestamp:first-child::before {
  content: "2015";
}

#timeline .timestamp:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
  /* Shorthand: flex: 0 1 0; */
}

#timeline .timestamp:last-child::before {
  content: 'NOW';
  font-size: 0.6rem;
  /* Shorthand: flex: 0 1 0; */
}

#experience .secondary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.portrait #experience .secondary {
  flex-direction: column;
}

#experience .secondary h3,
#experience .secondary p {
  margin: 0
}

#education {
  margin-top: 3rem;
  /*background-size: 75%;*/
  /*background-position-x: right;*/
  /*background-position-y: 75%;*/
  /*background-repeat: no-repeat;*/
}

.portrait #education {
  /*background-position-x: 110%;*/
  /*background-position-y: 75%;*/
  height: calc(100% - 12rem);
}

#education h2,
#education ul {
  margin: 0;
}

#education p {
  margin-top: 0;
}

#education p:last-of-type {
  margin-bottom: 0;
}

#itu {
  position: absolute;
  bottom: 0;
  text-align: right;
  font-style: italic;
}

#ranks {
  display: flex;
  flex-direction: row;
  /*width: 75%;*/
  justify-content: space-around;
}

#ranks button {
  width: 3rem;
  height: 3rem;
  position: relative;
}

.portrait #ranks button {
  width: 2rem;
  height: 2rem;
}

#ranks button img {
  width: 100%;
  filter: invert(1);
}

#ranks button img.blur {
  position: absolute;
  left: 0;
  transition: all 0.25s;
}

#ranks button img.blur.active {
  filter: invert(0.5) blur(0.75rem);
}

#ranks button:active img.blur {
  filter: invert(0.5);
}

.dark #ranks button img { filter: invert(1); }
.dark #ranks button img.blur.active { filter: invert(1) blur(0.75rem); }
.dark #ranks button:active img.blur { filter: invert(1); }

#ranks button p {
  margin: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

#interests .thumbs {
  position: relative;
  display: grid;
  width: calc(100% - 2rem);
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

#interests .thumbs img {
  width: 100%;
  height: auto;
}

#interests .thumbs p {
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 100%;
  margin: 0;
  text-align: center;
}

#interests span { transition: color 0.25s; }
#interests span.active { color: var(--link-color); }
#interests ul { margin-top: 0; }

#interests .player {
  position: relative;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
}

#interests .player:last-of-type {
  margin-bottom: 0;
}

#interests .player img {
  width: 100%;
  height: 100%;
}

#interests .player button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  /*margin-left: -2.5rem;*/
  /*margin-top: -1.5rem;*/
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
}

#interests ul {
  list-style-type: none;
}

#icons {
  display: flex;
  flex-direction: row;
  height: 5rem;
  justify-content: space-around;
  align-items: center;
}

#icons button {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
  position: relative;
  text-align: center;
}

.portrait #icons {
  height: 3rem;
}

#tv {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 500px;
  height: 270px;
  background-size: 72% 73%;
  background-position: 20% 42%;
  background-repeat: no-repeat;
  z-index: 2;
}

#tv p {
  position: absolute;
  margin: 0;
  /*top: -1rem;*/
  left: 0.5rem;
  font-size: 1.5rem;
}

#tv span {
  font-weight: bolder;
  color: var(--link-color);
}

#tv .overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100% 93.5%;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

#tv .player {
  position: absolute;
  /*top: 0;*/
  top: 12%;
  left: 6%;
  width: 72%;
  height: 72%;
  margin-bottom: 0.5rem;
}

#tv .player img {
  width: 100%;
  height: 100%;
}

#tv > button {
  position: absolute;
  display: flex;
  top: 15%;
  right: 4%;
  width: 12%;
  height: 22.225%;
  background-position: center;
  background-size: cover;
  align-items: center;
}

#tv > p.close {
  left: calc(100% + 2.5rem);
  bottom: 75%;
  margin: 0;
  color: var(--tv-color);
  white-space: nowrap;
  transform-origin: -20%;
  z-index: -1 !important;
}

#tv > p.close span {
  font-weight: bolder;
  color: #ac1f11;
}

#tv > p.drag {
  left: auto;
  right: 5%;
  color: var(--emphasize-color);
}

#tv .player button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  /*margin-left: -2.5rem;*/
  /*margin-top: -1.5rem;*/
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
}

#icons button {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

#projects p {
  font-size: 1.3rem;
  line-height: 2rem;
}

#projects .project {
  position: relative;
  display: inline-grid;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0;
  border-radius: 0.75rem;
  overflow: hidden;
}

#projects .project img {
  width: 100%;
  height: 100%;
}

#projects .project p {
  position: absolute;
  width: 100%;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*font-size: 3rem;*/
  /*line-height: 4.5rem;*/
  font-weight: bolder;
  color: white;
  text-shadow: 0 0 0.5rem black;
  z-index: 2;
}

#projects .project p span {
  font-style: italic;
  opacity: 0.75;
}

#projects .logo {
  height: 1rem;
  width: auto;
  margin-right: 0.2rem;
  /*margin-bottom: 0;*/
}

#projects table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

#projects tr {
  width: 33%;
  display: table-cell;
}

#projects thead tr { display: table-row; }

#projects th {
  padding: 1rem 0 0.5rem 0;
  font-size: 1.25rem;
  font-weight: normal;
  text-align: left;
}

#projects tr td {
  display: block;
  width: max-content;
}

#projects p.right { text-align: center; }

#projects .carousel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

#about a, #about span {
  color: var(--emphasize-color);
  font-weight: 600;
  line-height: 1.5rem;
}

/* #about a {
} */

#about .logo {
  height: 0.9rem;
  width: auto;
  margin: 0 0.2rem;
}

/*#about .mail {*/
/*  !*position: relative;*!*/
/*  !*width: max-content;*!*/
/*  pointer-events: auto;*/
/*}*/

/*#about .mail::after {*/
/*  position: absolute;*/
/*  display: none;*/
/*  top: 50%;*/
/*  right: 0;*/
/*  background-image: linear-gradient(*/
/*    to right,*/
/*    #6461fd,*/
/*    transparent 3rem*/
/*  );*/
/*  !*color: #b254ff;*!*/
/*  border-radius: 0.5rem;*/
/*  padding: 0.25rem 0.5rem;*/
/*  text-align: center;*/
/*  z-index: 999;*/
/*  content: var(--mail-tooltip);*/
/*  transform: translate(calc(100% + 0.6rem), -50%);*/
/*}*/

/*#about .mail::before {*/
/*  position: absolute;*/
/*  display: none;*/
/*  content: ' ';*/
/*  top: 50%;*/
/*  right: 0;*/
/*  width: 0.75rem;*/
/*  height: 0.75rem;*/
/*  background-image: linear-gradient(*/
/*    45deg,*/
/*    transparent -50%,*/
/*    #6461fd 50%,*/
/*    transparent 50%*/
/*  );*/
/*  z-index: 999;*/
/*  transform: translate(calc(100% + 0.3rem), -50%) rotate(45deg);*/
/*}*/

/*#about .mail:active::after,*/
/*#about .mail:hover::after {*/
/*  display: block;*/
/*}*/

/*#about .mail:active::before,*/
/*#about .mail:hover::before {*/
/*  display: block;*/
/*}*/

#back {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--background-color);
  background-image: linear-gradient(170deg, transparent -50%, var(--color) 150%);
  color: var(--background-color);
  border-radius: 2rem;
  z-index: 2;
}

#scene {
  position: absolute;
  left: 0;
  top: 0;
}

#vignette {
  position: absolute;
  right: 0;
  top: 0;
}

.cursor {
  position: absolute;
  z-index: 5;
}

.cursor.dark {
  filter: invert(1);
}

@media screen and (max-width: 1024px) {
  .section {
    width: calc(var(--width) * 0.6);
  }

  #projects .carousel {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
  }
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 600px;*/
  /*height: 350px;*/
  /*background: #ffad00;*/
  border-radius: 500px;
  /*border: solid 5px transparent;*/
  background-clip: padding-box;
  cursor: pointer;
  /*transform: scale(0.1);*/
  transform-origin: 100% 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1.6rem;
  z-index: 2;
  transition: all var(--duration);
}

.toggle p {
  position: absolute;
  bottom: 0;
  right: 120%;
  margin: 0;
  text-align: end;
}

.portrait .toggle { margin: 1rem; }

.portrait .toggle.top {
  /*top: 0;*/
  bottom: calc(100% - 3.5rem);
}

.toggle__wrapper {
  width: 3rem;
  height: 1.5rem;
  /*margin: -4px -2px;*/
  /*border-radius: 500px;*/
  background: transparent;
  /*box-shadow: 0px 5px 1px 0 #22222222;*/
  /*width: 500px;*/
  /*height: 250px;*/
}

.toggle__bg {
  width: 3rem;
  height: 1.5rem;
  background: transparent;
  background: linear-gradient(transparent 50%, var(--color) 200%);
  background-size: 200% 100%;
  background-position: 75%;
  border-radius: 500px;
  /*border: solid 5px transparent;*/
  background-clip: padding-box;
  /*box-shadow: inset 0px 1rem 2rem 0.5rem hsla(0, 0%, 0%, 0.30);*/
  transition: all 0.85s ease-in-out;
  /*width: 500px;*/
  /*height: 250px;*/
}

.toggle__sphere {
  position: relative;
  height: 1.3rem;
  width: 1.3rem;
  /*background: hsl(18, 100%, 62%);*/
  background:
          linear-gradient(to right, hsl(0, 0%, 100%) 50%, hsl(0, 0%, 34%) 50% 100%);
  border-radius: 50%;
  transform-origin: top left;
  transform: translateX(7%) translateY(7%);
  /*box-shadow:*/
  /*        0.5px 0px 2px 0.5px hsla(18, 77%, 15%, 0.05),*/
  /*        0px 5px 5px 0.5px hsla(18, 77%, 15%, 0.2),*/
  /*        -1px 0px 1px 0.5px hsla(0, 0%, 0%, 0.75);*/
  transition: all var(--duration) ease-in-out;
}

/*.toggle__sphere:before,*/
/*.toggle__sphere:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  left: calc(50% - 20px);*/
/*  height: 1px;*/
/*  width: 40px;*/
/*  background: hsla(0, 0%, 0%, 0.25);*/
/*  border-radius: 50%;*/
/*  box-shadow: 0px 0px 10px 0px hsla(0, 0%, 0%, 0.25);*/
/*}*/

/*.toggle__sphere:before {*/
/*  top: -2px;*/
/*}*/

/*.toggle__sphere:after {*/
/*  bottom: -1px;*/
/*}*/

.toggle__sphere-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50%;
  /*box-shadow: inset 0px -1rem 1rem 0.5rem hsla(0, 0%, 0%, 0.1)*/
}

.toggle__sphere-bg {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  border-radius: 50%;
  transition: transform var(--duration) ease-in-out;
}

.toggle__sphere-bg:before,
.toggle__sphere-bg:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.toggle__sphere-bg:before {
  background-color: #2d2d2d;
  border-radius: 50%;
}

.toggle__sphere-bg:after {
  background-color: hsl(0, 0%, 100%);
  transform: rotateY(180deg);
  border-radius: 50%;
}

input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

input:checked + .toggle__bg {
  /*background-position: 25%;*/
  background: linear-gradient(transparent 50%, var(--color) 200%);
}

input:checked + .toggle__bg .toggle__sphere {
  transform: translateX(123%) translateY(7%);
  /*box-shadow:*/
  /*        -0.5px 0px 2px 0.5px hsla(18, 77%, 15%, 0.05),*/
  /*        0px 5px 5px 0.5px hsla(18, 77%, 15%, 0.2),*/
  /*        1px 0px 1px 0.5px hsla(0, 0%, 0%, 0.75);*/
}

input:checked + .toggle__bg .toggle__sphere-bg {
  transform: rotateY(180deg);
}






