html, body, canvas {
  margin: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 150%;
}

canvas {
  position: absolute;
  /*z-index: -1;*/
}